@tailwind base;
@tailwind components;
@tailwind utilities;

.main-content {
    padding-top: 60px;
}

body {
  background: linear-gradient(to right, #3a4920 0%, #3a4920 100%);
}

.project-card-container {
  height: 200px;
  width: 500px; /* Adjust this value according to your design */
  /* Add other styles as needed */
}


@keyframes bounceIn {
    from, 20%, 40%, 60%, 80%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }
    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        transform: scale3d(.9, .9, .9);
    }
    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        transform: scale3d(.97, .97, .97);
    }
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

@keyframes rubberBand {
    from {
        transform: scale3d(1, 1, 1);
    }
    30% {
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        transform: scale3d(.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, .95, 1);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}
